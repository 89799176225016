<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="حفظ"
      back-button-text="سابق"
      next-button-text="تالي"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content
        title="معلومات العقار"
        :before-change="validationForm"
      >
        <evaluation-request-add 
        :contract="contractData"
        :cost="costData"
        />
      </tab-content>

      <!-- ownership info tab -->
      <tab-content
        title="معلومات الملكية"
        :before-change="validationOwnershipForm"
      >
        <ownership-info  v-if="evaluationRequestData && evaluationRequestData.property"
          :property-type="evaluationRequestData.property.property_type_id" />
      </tab-content>

      <!-- address  -->
      <tab-content
        title="الأطوال والمساحات"
        :before-change="validationDimensionForm"
      >
        <dimensions-info
          v-if="evaluationRequestData && evaluationRequestData.property"
          :property-type="evaluationRequestData.property.property_type_id"
        />
      </tab-content>

      <!-- social link -->
      <tab-content
        title="المرفقات"
        :before-change="validationAttachmentsForm"
      >
        <attachments />
      </tab-content>
    </form-wizard>
  </div>
</template>
<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { FormWizard, TabContent } from 'vue-form-wizard';
import evaluationRequestStore from '../evaluationRequestStore';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import EvaluationRequestAdd from './general-info/EvaluationRequestAdd.vue';
import OwnershipInfo from './ownership-info/OwnershipInfo.vue';
import DimensionsInfo from './dimensions-info/DimensionsInfo.vue';
import Attachments from './attachments/Attachments.vue';
import { serialize } from 'object-to-formdata';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    FormWizard,
    TabContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    EvaluationRequestAdd,
    OwnershipInfo,
    DimensionsInfo,
    Attachments,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin],
  data() {
    return {
      type: 'contract',
      quotations: [],
      isLoading: false,
      evaluationRequestData: null,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
  },
  methods: {
    formSubmitted() {
      this.addEvaluationRequest();
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$root.$emit('validateGeneralInfo');
        this.$root.$on('generalInfoValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData = data;
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
      });
    },
    validationOwnershipForm() {
      return new Promise((resolve, reject) => {
        this.$root.$emit('validateOwnership');
        this.$root.$on('ownershipValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData = { ...this.evaluationRequestData, ...data };
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
      });
    },
    validationDimensionForm() {
      return new Promise((resolve, reject) => {
        this.$root.$emit('validateDimensions');
        this.$root.$on('dimensionsValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData = { ...this.evaluationRequestData, ...data };
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
      });
    },
    validationAttachmentsForm() {
      return new Promise((resolve, reject) => {
        this.$root.$emit('validateAttachments');
        this.$root.$on('attachmentsValidated', ({ success, data }) => {
          if (success) {
            this.evaluationRequestData = { ...this.evaluationRequestData, ...data };
            resolve(true);
          } else {
            reject('الرجاء التأكد من صحة البيانات');
          }
        });
      });
    },
    typeChanged(type) {
      this.evaluationRequestData.type = type;
      this.evaluationRequestData.client_id = null;
      this.evaluationRequestData.contract_id = null;
      this.evaluationRequestData.quotation_id = null;
      this.filteredClients = this.filterClientList(type);
    },
    filterClientList(type) {
      return this.clients.filter((client) => {
        if (type === 'contract') {
          return client.type !== 'individuals';
        }
        return client.type === 'individuals';
      });
    },
    quotation_idChanged(quotation_id) {
      this.evaluationRequestData.contract_id = null;
      this.evaluationRequestData.quotation_id = quotation_id;
    },
    contract_idChanged(contract_id) {
      this.evaluationRequestData.quotation_id = null;
      this.evaluationRequestData.contract_id = contract_id;
    },
    clientChanged(client_id) {
      this.evaluationRequestData.client_id = client_id;
      this.evaluationRequestData.contract_id = null;
      this.evaluationRequestData.quotation_id = null;

      this.contracts = this.filteredClients
        .find((client) => client.id === client_id)
        .contracts.map((c) => ({
          label: c.reference,
          value: c.id,
          ...c,
        }));
      this.quotations = this.filteredClients
        .find((client) => client.id === client_id)
        .quotations.map((c) => ({
          label: c.reference,
          value: c.id,
          ...c,
        }));
    },
    statusChanged(status) {
      this.evaluationRequestData.status_id = status;
    },
    addEvaluationRequest() {
      const data = serialize(this.evaluationRequestData, { indices: true });
      this.$store
        .dispatch(
          'evaluationRequest/addEvaluationRequest',
          data,
        )
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push('/evaluation-request').catch(() => {});
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
    const blankEvaluationRequestData = {
      client_id: null,
      type: 'contract',
      contract_id: null,
      quotation_id: null,
    };

    const typeOptions = [
      {
        text: 'عقود',
        value: 'contract',
      },
      {
        text: 'عملاء',
        value: 'quotation',
      },
    ];

    const evaluationRequestData = ref(
      JSON.parse(JSON.stringify(blankEvaluationRequestData)),
    );
    const resetevaluationRequestData = () => {
      evaluationRequestData.value = JSON.parse(
        JSON.stringify(blankEvaluationRequestData),
      );
    };

    const clients = ref([]);
    const filteredClients = ref([]);
    const contracts = ref([]);
    const res = store
      .dispatch('evaluationRequest/fetchClients')
      .then((response) => {
        const { data } = response.data;

        clients.value = data.map((c) => ({
          label: c.name,
          value: c.id,
          ...c,
        }));
        filteredClients.value = clients.value.filter(
          (c) => c.type !== 'individuals',
        );

        if (props.contract) {
          evaluationRequestData.value.type = 'contract';
          filteredClients.value = clients.value.filter(
            (c) => c.type !== 'individuals',
          );
          evaluationRequestData.value.client_id = props.contract.client_id;
          contracts.value = filteredClients.value
            .find((client) => client.id === props.contract.client_id)
            .contracts.map((c) => ({
              label: c.reference,
              value: c.id,
              ...c,
            }));
          evaluationRequestData.value.contract_id = props.contract.id;
        }
      });

    const statuses = [
      {
        label: 'نشط',
        value: 'نشط',
      },
      {
        label: 'ملغى',
        value: 'ملغى',
      },
      {
        label: 'منتهي',
        value: 'منتهي',
      },
    ];

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationRequestData,
    );
    const contractData = ref(null);
    const costData = ref(null);
    if (router.currentRoute.params.id !== undefined) {
    store.dispatch('evaluationRequest/fetchContract', { id: router.currentRoute.params.id })
      .then((response) => {
        const {data: contract } = response.data;
        const { data } = response.data;
        contractData.value = contract;
        costData.value = data.cost;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          contractData.value = undefined;
        }
      });
    }
    return {
      contractData,
      costData,
      filteredClients,
      evaluationRequestData,
      clients,
      contracts,
      typeOptions,
      statuses,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

</style>
